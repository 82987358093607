import * as React from "react";
import classNames from "classnames";

const Recommendation = ({ data, recommendationNumbers }) => {
  let recommendationDresses = data;
  let getRecommendation = (data) => {
    return recommendationNumbers.map((e, index) => {
        if( recommendationDresses[e] != null){
            return (
                <div className="recommendation-product">
                  <a
                  class="hover:opacity-75"
                    href={`/products/cb-${recommendationDresses[
                      e
                    ].node.data.product_title
                      .trim()
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                  >
                    <img loading="lazy" fetchpirority="low" src={recommendationDresses[e].node.data.product_image} alt={recommendationDresses[e].node.data.product_title}/>
                  </a>
                  <a
                    className="product-link "
                    href={`/products/cb-${recommendationDresses[
                      e
                    ].node.data.product_title
                      .trim()
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                  >
                    <p className="font-bold hover:opacity-75">
                      ct-
                      {recommendationDresses[e].node.data.product_title
                        .split(/\b/)
                        .reverse()
                        .join("")}
                    </p>
                  </a>
        
                  {/* <span>{data.company}</span> */}
                  <p className="mb-2">
                    {recommendationDresses[e].node.data.product_price}
                  </p>
                  {/* <p className="product-description md:text-sm max-w-md">
                    {recommendationDresses[e].node.data.product_description }
                </p> */}
                </div>
              );
        } else{
            return
        }
      
    });
  };

  return (
    <div id="recommendation-container" className="pt-4 pb-0">
      <h2 class="text-2xl font-extrabold">You May Also Like</h2>
      <div className="recommendation-product-container">
        {recommendationDresses != null ? getRecommendation(data) : null}
      </div>
    </div>
  );
};

export default Recommendation;
